import React from "react";
import "./CarPosition.css";


//shows the map and the arrivalTime in the JourneyInfos
//If implementing a (google-)Map, it would be here
export class CarPosition extends React.Component {

  render() {
      //sets "small" or "big" to the class attibute -> css
      const myClassNames = "map " + this.props.size;

      return (
        <div className={myClassNames}>
          <div className="arrivalTime">
            <div className="arrivalTimeSpan">Ankunft: <br/>
            {this.props.arrivalTime}</div>
          </div>
        </div>
    );
  }
}

export default CarPosition;
