import React from "react";
import PropTypes from "prop-types";
import { Code } from "./style";

const propTypes = {
  location: PropTypes.object.isRequired
};

// display a error page if using a non matching route, displaying the missmatching route to the user
export default function PageNotFound({ location }) {
  return (
    <p>
      Page not found - the path, <Code>{location.pathname}</Code>, did not match
      any React Router routes.
    </p>
  );
}

PageNotFound.propTypes = propTypes;
