import React from "react";
import "./Journey.css";
import BookingModalNew from "./BookingModalNew.js";
import BookingModalOnePassenger from "./BookingModalOnePassenger.js";
import BookingModalTwoPassenger from "./BookingModalTwoPassenger.js";

// this component renders the different journeys which are displayed in the booking view.
// When clicking a journey, open a popup which enables the booking of this journey.
export class Journey extends React.Component {
  // fill the render object with the proper journey (one/two passengers, new journey)
  renderObject = null;

  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open: false
    };
    console.log("Constror state: " + this.state.open);
  }

  // open a popup to book the selected journey
  openModal = () => {
    this.setState({ open: true }, () =>
      console.log("modal opened in Parent. State = " + this.state.open)
    );
  };

  // receive the callback from a closing popup/modal and set the open state to receive a proper open/close logic.
  closeModal(newState) {
    this.setState({ open: newState }, () =>
      console.log("modal closed in Parent. State = " + this.state.open)
    );
  }

  // set the render object to render a new journey and integrate the bookingModal
  renderNewJourney() {
    this.renderObject = (
      <div>
        <div className="outter" onClick={this.openModal.bind(this)}>
          <p className="arrival">Ankunft am Ziel: ~ {this.props.arrival} Uhr</p>
          <p className="price">max. {this.props.price} €</p>
          {this.state.open && (
            <BookingModalNew
              open={this.state.open}
              triggerCloseModal={this.closeModal.bind(this)}
              destination={this.props.destination}
              arrival={this.props.arrival}
              price={this.props.price}
              passengers={this.props.passengers}
              live={this.props.live}
            />
          )}
          <div className="watchingDiv">
            <img
              className="watchingImg"
              src={require("../assets/Fernseher.png")}
              alt="actually watching"
            />
            <p className="watchingSuggestion">
              Mache deinen Mitfahreren einen Vorschlag, etwas gemeinsam zu
              schauen.
            </p>
          </div>
          <img
            className="newJourneyImg"
            src={require("../assets/neueFahrt.png")}
            alt="create a new journey"
          />
          <p className="newJourneyP">Neue Fahrt</p>
        </div>
      </div>
    );
    return this.renderObject;
  }

  // set the render object to render a journey with one passenger and integrate the bookingModal
  renderOnePassengerJourney() {
    this.renderObject = (
      <div>
        <div className="outter" onClick={this.openModal.bind(this)}>
          <p className="arrival">Ankunft am Ziel: ~ {this.props.arrival} Uhr</p>
          <p className="price">max. {this.props.price} €</p>
          {this.state.open && (
            <BookingModalOnePassenger
              open={this.state.open}
              triggerCloseModal={this.closeModal.bind(this)}
              destination={this.props.destination}
              arrival={this.props.arrival}
              price={this.props.price}
              img_1={this.props.img_1}
              name_1={this.props.name_1}
              age_1={this.props.age_1}
              matching_1={this.props.matching_1}
              live={this.props.live}
              media={this.props.media}
              passengers={this.props.passengers}
            />
          )}
          {this.props.live && (
            <div className="watchingDiv">
              <img
                className="watchingImg"
                src={require("../assets/Fernseher.png")}
                alt="actually watching"
              />
              <p className="watchingProp">{this.props.media}</p>
            </div>
          )}
          {!this.props.live && (
            <div className="watchingDiv">
              <img
                className="watchingImg"
                src={require("../assets/Fernseher.png")}
                alt="actually watching"
              />
              <p className="watchingSuggestion">
                Mache deinen Mitfahreren einen Vorschlag, etwas gemeinsam zu
                schauen.
              </p>
            </div>
          )}
          <div className="personDiv_2">
            <img className="img" src={this.props.img_1} alt="DummyPicture" />
            <div className="personInfos">
              <p className="personName">
                {this.props.name_1}
                <span className="personAge">({this.props.age_1} J.)</span>
              </p>
              <p className="personMatching">
                {this.props.matching_1}% gemeinsame Interessen
              </p>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
    console.log("some logging");
    console.log(this.renderObject);
    return this.renderObject;
  }

  // set the render object to render a journey with two passengers and integrate the bookingModal
  renderTwoPassengerJourney() {
    this.renderObject = (
      <div>
        <div className="outter" onClick={this.openModal.bind(this)}>
          <p className="arrival">Ankunft am Ziel: ~ {this.props.arrival} Uhr</p>
          <p className="price">max. {this.props.price} €</p>
          {this.state.open && (
            <BookingModalTwoPassenger
              open={this.state.open}
              triggerCloseModal={this.closeModal.bind(this)}
              destination={this.props.destination}
              arrival={this.props.arrival}
              price={this.props.price}
              img_1={this.props.img_1}
              name_1={this.props.name_1}
              age_1={this.props.age_1}
              matching_1={this.props.matching_1}
              img_2={this.props.img_2}
              name_2={this.props.name_2}
              age_2={this.props.age_2}
              matching_2={this.props.matching_2}
              live={this.props.live}
              media={this.props.media}
              passengers={this.props.passengers}
            />
          )}
          {this.props.live && (
            <div className="watchingDiv">
              <img
                className="watchingImg"
                src={require("../assets/Fernseher.png")}
                alt="actually watching"
              />
              <p className="watchingProp">{this.props.media}</p>
            </div>
          )}
          {!this.props.live && (
            <div className="watchingDiv">
              <img
                className="watchingImg"
                src={require("../assets/Fernseher.png")}
                alt="actually watching"
              />
              <p className="watchingSuggestion">
                Mache deinen Mitfahreren einen Vorschlag, etwas gemeinsam zu
                schauen.
              </p>
            </div>
          )}
          <div className="personDiv">
            <img className="img" src={this.props.img_1} alt="DummyPicture" />
            <div className="personInfos">
              <p className="personName">
                {this.props.name_1}
                <span className="personAge">({this.props.age_1} J.)</span>
              </p>
              <p className="personMatching">
                {this.props.matching_1}% gemeinsame Interessen
              </p>
            </div>
          </div>
          <div className="personDiv_2">
            <img className="img" src={this.props.img_2} alt="DummyPicture" />
            <div className="personInfos">
              <p className="personName">
                {this.props.name_2}
                <span className="personAge">({this.props.age_2} J.)</span>
              </p>

              <p className="personMatching">
                {this.props.matching_2}% gemeinsame Interessen
              </p>
            </div>
          </div>
        </div>
      </div>
    );
    return this.renderObject;
  }

  // distinguish between the kind of journey given from passengers prop in booking view and fill the renderObject to render it to the user
  render() {
    switch (this.props.passengers) {
      case "0":
        this.renderObject = this.renderNewJourney();
        break;
      case "1":
        this.renderObject = this.renderOnePassengerJourney();
        break;
      case "2":
        this.renderObject = this.renderTwoPassengerJourney();
        break;
      default:
        this.renderObject = <h1>Error</h1>;
    }
    return this.renderObject;
  }
}

export default Journey;
