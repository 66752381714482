import React from "react";
import Switch from "react-switch";
import "./CurrentMedia.css";
import Passenger from "./Passenger.js";
import { MediaCover } from "./MediaCover.js";
import video from "../assets/video_Fußball.mp4";
import audio from "../assets/audio_Fußball.mp3";

//this view, shows the Information about the current booked journey
//the content distinguishes between a live media overview or a media selection
//State.open is true when content is shown, false when map is shown.
//State.screen distinguishes between the different screen variations.
export class Media extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      open: false,
      screen: "selectableContent",
      autoStart: false,
      invitationChecked: false
    };
  }

//switches to contentInfos when clicked on a cover
  toContentInfos() {
    this.setState({ screen: "contentInfos" });
  }
//sitches back to selection of all Media proposals
  ToSelectableContent() {
    this.setState({ screen: "selectableContent" });
  }

//toggles appearence of the autostart button, in the contentInfo overview
  toggleAutoStart() {
    this.setState({ autoStart: !this.state.autoStart });
  }
//toggles the checkbox of the invitation to pessengers in the contentInfo overview
  toggleInvitation() {
    this.setState( {invitationChecked : !this.state.invitationChecked })
  }

//rendes the Information about the current Journey, depending on the selected journey
  render() {
    let containerStyle;
    //disables Scroll when the content is small and the map is big
    if (this.props.disableScroll) {
      containerStyle = {
        overflowY: "hidden"
      };
    }

    let topicOne;
    let topicThree;

    //changes topics depending on the choosen journey. Workaround
    if (this.props.passengers === "1") {
      topicOne = "TopTipp: gemeinsame Interessen";
      topicThree = "Mal was neues ausprobieren: Paulas Interessen";
    } else {
      topicOne = "Weiterschauen";
      topicThree = "Derzeit Beliebt";
    }

    //manages appearence of the autoStart-Button
    let autoStartButtonStyle;
    let autoStartButtonText;
    let autoStartImg;
    if (this.state.autoStart) {
      autoStartButtonStyle = {
        color: "#FFFFFF",
        backgroundColor: "#57628d",
        border: "1px outset #EEEEEE",
        borderRadius: "25px",
        fontWeight: "bold",
        textAlign: "center",
        width: "290px",
        left: "0",
        right: "0",
        margin: "auto"
      };
      autoStartButtonText = "wird automatisch gestartet";
      autoStartImg = require("../assets/Pause.svg");
    } else {
      autoStartButtonStyle = {
        color: "#57628d",
        border: "1px outset #EEEEEE",
        borderRadius: "25px",
        fontWeight: "bold",
        textAlign: "center",
        width: "260px",
        left: "0",
        right: "0",
        margin: "auto"
      };
      autoStartButtonText = "beim Einsteigen starten";
      autoStartImg = require("../assets/Play.svg");
    }


    // shows a summary of the current played Media
    let summary = (
      <div className="live-enteraiment">
        <h1>Unterhaltung unterwegs</h1>
        <img
          src={require("../assets/Zeitleiste.svg")}
          alt="Timeline"
          className="Timeline"
        />
        <h3>Fußball-EM 2024 | Frankreich - Kroatien ZDF Live</h3>
        <p>
          {" "}
          Wenn du dazu steigst, haben deine Mitfahrer schon 30 Minuten geschaut.
          Hier deine Zusammenfassung von allem, was bisher geschah:
        </p>

        <video className="media" controls="true">
          <source src={video} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>

        <p>
          1 - 0 für Frankreich. Das erste Tor wurde in der 18. Minute durch
          Antoine Griezmann geschossen.
        </p>
        <p>Audio-Zusammnfassung:</p>
        <audio className="media" controls="true">
          <source src={audio} type="audio/mp3" />
          Your browser does not support HTML5 video.
        </audio>
      </div>
    );

    // shows a proposal of the media that can be watched
    let selectableContent = (
      <div className="live-enteraiment">
        <h1>Unterhaltung unterwegs</h1>
        <h3>Was möchtest du schauen?</h3>
        <div className="SearchMedia">
          <img
            className="Lens"
            src={require("../assets/Lupe.svg")}
            alt="back button"
          />
          <input type="text" placeholder="Suchen" style={{ border: "none" }} />
        </div>
        <h3>{topicOne}</h3>
        <div className="MediaCoverContainer">
          <MediaCover
            name="Human"
            broadcaster="Amazon"
            click={this.toContentInfos.bind(this)}
          />
          <MediaCover name="HeuteJournal" broadcaster="ZDF" />
          <MediaCover name="InsideJob" broadcaster="Netflix" />
        </div>
        <h3>Deine Empfehlungen</h3>
        <div className="MediaCoverContainer">
          <MediaCover name="SOKOLeipzig" broadcaster="ZDF" />
          <MediaCover name="Split" broadcaster="Amazon" />
          <MediaCover name="BigBangTheory" broadcaster="Netflix" />
        </div>
        <h3>{topicThree}</h3>
        <div className="MediaCoverContainer">
          <MediaCover name="LuckyOne" broadcaster="Amazon" />
          <MediaCover name="FürImmerSingle" broadcaster="Amazon" />
          <MediaCover name="FedernImWind" broadcaster="Netflix" />
        </div>
      </div>
    );

    //shows the Information about a current show,movie etc.
    let contentInfos = (
      <div className="live-enteraiment">
        <h1>Unterhaltung unterwegs</h1>
        <div className="MediaDescribtion">
          <MediaCover name="Human" broadcaster="Amazon" float="right" />
          <div className="coverInfos">
            <p>
              Human
              <br />
              ZDF
            </p>
            <p>
              Film
              <br />
              Genre: Doku
            </p>
          </div>
          <img
            src={require("../assets/ZurückpfeilBlue.png")}
            alt="back button"
            style={{ height: "21px", width: "12px", margin: "10px" }}
            onClick={this.ToSelectableContent.bind(this)}
          />
        </div>
          <div
          style={{textAlign: "center", verticalAlign: "middle", margin: "2rem"}}>
            <Switch
            className="toogleInvitation"
            onChange={this.toggleInvitation.bind(this)}
            checked={this.state.invitationChecked}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#57628d"
            offColor="#e2e2e2"
            height={this.height}
            width={this.width}
            handleDiameter={this.diameter}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            />
          <div style={{textAlign: "left"}}>
            <span>
              Mitfahrer zum gemeinsamen Schauen einladen
            </span>
          </div>
        </div>
        <div className="inviteToSharedWatching">
          <div
            style={autoStartButtonStyle}
            onClick={this.toggleAutoStart.bind(this)}
          >
            <img
              src={autoStartImg}
              alt="Play Button"
              style={{
                width: "30px",
                height: "30px",
                verticalAlign: "middle",
                margin: "5px",
                marginRight: "15px"
              }}
            />
            {autoStartButtonText}
          </div>
        </div>
      </div>
    );

    //sets content depending on given properties and State.screen
    let content;
    if (this.props.contentSummary) {
      content = summary;
    } else if (this.state.screen === "selectableContent") {
      content = selectableContent;
    } else if (this.state.screen === "contentInfos") {
      content = contentInfos;
    }

    //shows passengers on the bottom of the screen, the choosen ones depends on the journey.
    let passengers;
    if (this.props.passengers === "0" || this.props.passengers === undefined) {
      passengers = "";
    } else if (this.props.passengers === "1") {
      passengers = (
        <div className="passengers">
          <h1 style={{ textAlign: "left" }}>Mitfahrer</h1>
          <Passenger
            img={require("../assets/paula.png")}
            name="Paula"
            age="57"
            matching="25"
          />
        </div>
      );
    } else {
      passengers = (
        <div className="passengers">
          <h1 style={{ textAlign: "left" }}>Mitfahrer</h1>
          <Passenger
            img={require("../assets/frank.png")}
            name="Frank"
            age="41"
            matching="100"
          />
          <Passenger
            img={require("../assets/theo.png")}
            name="Theo"
            age="17"
            matching="75"
          />
        </div>
      );
    }

    // the returned element is assembled from the content and the passenger.
    return (
      <div className="container" style={containerStyle}>
        {content}
        {passengers}
      </div>
    );
  }
}

export default Media;
