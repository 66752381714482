import React from "react";
import Header from "../components/Header";

import { withRouter } from "react-router-dom";
import "./Profile.css";

// this view shows the "Öffentliches Profil" or passenger profile to the user
class Profile extends React.Component {
  constructor(props) {
    super(props);
    const name = localStorage.getItem("profileName");
    const age = localStorage.getItem("profileAge");
    const back = localStorage.getItem("back");
    this.state = {
      name: name,
      age: age,
      back: back
    };
  }

  // Dependend of the given user, get his profile image.
  getProfileImage() {
    var image;
    console.log(this.state.name);
    switch (this.state.name) {
      case "Stefan":
        image = require("../assets/eigenesProfil.png");
        break;
      case "Frank":
        image = require("../assets/frank.png");
        break;
      case "Theo":
        image = require("../assets/theo.png");
        break;
      case "Paula":
        image = require("../assets/paula.png");
        break;
      default:
        image = null;
    }

    return image;
  }

  // Dependend of the given user, get his interests.
  getInterests() {
    var interests;
    switch (this.state.name) {
      case "Stefan":
        interests = (
          <div className="interests">
            <p className="interestsP">Interessen</p>
            <div className="interestsContainerOutter">
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Fußball.png")}
                  alt="Soccer interest"
                />
                <p className="interestTag">Fußball</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Doku.png")}
                  alt="documentation interest"
                />
                <p className="interestTag">Doku</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Krimi.png")}
                  alt="crime interest"
                />
                <p className="interestTag">Krimi</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Comedy.png")}
                  alt="comedy interest"
                />
                <p className="interestTag">Comedy</p>
              </div>
            </div>
            <img
              className="addInterest"
              src={require("../assets/interests/Plus.svg")}
              alt="add interest"
            />
          </div>
        );
        break;
      case "Frank":
        interests = (
          <div className="interests">
            <p className="interestsP">Interessen</p>
            <div className="interestsContainerOutter">
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Fußball.png")}
                  alt="Soccer interest"
                />
                <p className="interestTag">Fußball</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Doku.png")}
                  alt="documentation interest"
                />
                <p className="interestTag">Doku</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Krimi.png")}
                  alt="crime interest"
                />
                <p className="interestTag">Krimi</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Comedy.png")}
                  alt="comedy interest"
                />
                <p className="interestTag">Comedy</p>
              </div>
            </div>
          </div>
        );
        break;
      case "Theo":
        interests = (
          <div className="interests">
            <p className="interestsP">Interessen</p>
            <div className="interestsContainerOutter">
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Fußball.png")}
                  alt="Soccer interest"
                />
                <p className="interestTag">Fußball</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Horror.png")}
                  alt="horror interest"
                />
                <p className="interestTag">Horror</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Krimi.png")}
                  alt="crime interest"
                />
                <p className="interestTag">Krimi</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Comedy.png")}
                  alt="comedy interest"
                />
                <p className="interestTag">Comedy</p>
              </div>
            </div>
          </div>
        );
        break;
      case "Paula":
        interests = (
          <div className="interests">
            <p className="interestsP">Interessen</p>
            <div className="interestsContainerOutter">
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Doku.png")}
                  alt="documentation interest"
                />
                <p className="interestTag">Doku</p>
              </div>
              <div className="interestContainer">
                <img
                  className="interestImg"
                  src={require("../assets/interests/Romantik.png")}
                  alt="romantic interest"
                />
                <p className="interestTag">Romantik</p>
              </div>
            </div>
          </div>
        );
        break;
      default:
        interests = null;
    }
    return interests;
  }

  // render the profile including its profile image, name, age and its interests
  render() {
    const img = this.getProfileImage();
    const interests = this.getInterests();
    return (
      <div>
        {this.state.name === "Stefan" && (
          <Header
            toSite={this.state.back}
            title="Öffentliches Profil"
            profilelink={false}
          />
        )}
        {this.state.name !== "Stefan" && (
          <Header
            toSite={this.state.back}
            title={this.state.name + "'s Profil"}
            profilelink={false}
          />
        )}
        <div className="outterProfile">
          <div className="pic">
            <img
              className="profileImg"
              src={img}
              alt={this.state.name + "profile"}
            />
            <div className="topProfileFlexContainer">
              <p className="profileTag">Name </p>
              <p className="profileProp">{this.state.name}</p>
            </div>
            <div className="bottomProfileFlexContainer">
              <p className="profileTag">Alter </p>
              <p className="profileProp">{this.state.age} J.</p>
            </div>
          </div>
        </div>
        {interests}
      </div>
    );
  }
}

export default withRouter(Profile);
export { Profile };
