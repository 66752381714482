import React from "react";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import "./BookingModalOnePassenger.css";

// shows the bookingModal popup if the user clicks on the journey with Paula in booking view. This component is opend from journey component
// if there is an api/backend integrated, this method displays every bookingModal popup for a journey with two passengers.
class BookingModalOnePassenger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  // close the modal if clicking outside it or on close button. Also fire a callback to the journey component to obtain a working open/close logic of the modal
  onCloseModal = () => {
    console.log("before closed inside children. State = " + this.state.open);
    this.setState(
      { open: false },
      () => this.props.triggerCloseModal(this.state.open),
      console.log("closed inside children. State = " + this.state.open)
    );
  };

  // if a user clicks on a profile image, route to profile view to give an overview of his/her profile with interests
  handleProfileClickOne() {
    localStorage.setItem("profileName", this.props.name_1);
    localStorage.setItem("profileAge", this.props.age_1);
    localStorage.setItem("back", "/booking");
    this.props.history.push("/profile");
  }

  // safe all necessary variables to localStorage and route to bookingConfirmation view. This method books the given journey
  onButtonClick() {
    localStorage.setItem("price", this.props.price);
    localStorage.setItem("passengers", this.props.passengers);
    localStorage.setItem("live", this.props.live);

    this.props.history.push("/booked", {});
  }

  // renders the bookingModal popup to book the journey
  render() {
    const open = this.state;
    const bg = {
      transitionEnter: {
        transform: "scale(0)"
      },
      transitionEnterActive: {
        transform: "scale(1)",
        transition: "transform 1000ms ease"
      },
      transitionExit: {
        transform: "scale(1)"
      },
      transitionExitActive: {
        transition: "transform 1000ms ease"
      }
    };
    return (
      <div className="childDiv">
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          styles={bg}
          animationDuration={1000}
        >
          <div className="outterBooking">
            <div className="destinationBooking">
              <img src={require("../assets/Location.png")} alt="destination" />
              <span>{this.props.destination}</span>
            </div>
            <p className="arrivalBooking">
              Ankunft am Ziel: ~ {this.props.arrival} Uhr
            </p>
            {this.props.live && (
              <div className="watchingBookingDiv">
                <img
                  className="watchingBookingImg"
                  src={require("../assets/Fernseher.png")}
                  alt="actually watching"
                />
                <p className="watchingBookingProp">{this.props.media}</p>
              </div>
            )}
            {!this.props.live && (
              <div className="watchingBookingDiv">
                <img
                  className="watchingBookingImg"
                  src={require("../assets/Fernseher.png")}
                  alt="actually watching"
                />
                <p className="watchingBookingSuggestion">
                  Mache deinen Mitfahreren einen Vorschlag, etwas gemeinsam zu
                  schauen.
                </p>
              </div>
            )}
            <div className="personBookingDiv">
              <img
                className="imgBooking"
                src={this.props.img_1}
                alt="DummyPicture"
                onClick={this.handleProfileClickOne.bind(this)}
              />
              <div className="personBookingInfos">
                <p className="personBookingName">
                  {this.props.name_1}
                  <span className="personBookingAge">
                    ({this.props.age_1} J.)
                  </span>
                </p>
                <p className="personBookingMatching">
                  {this.props.matching_1}% gemeinsame Interessen
                </p>
              </div>
            </div>

            <div className="bookingDiv">
              <p className="bookingMaxPrice">max.</p>
              <p className="priceBooking">{this.props.price}€</p>
            </div>
            <div className="alignCenterDiv">
              <p className="payFormBooking">Zahlungsart: PayPal</p>
            </div>
            <div className="alignCenterDiv">
              <button
                onClick={this.onButtonClick.bind(this)}
                align="center"
                className="buttonBooking"
              >
                Buchen
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BookingModalOnePassenger);
