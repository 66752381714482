import React from "react";
import swal from "sweetalert";
import "./Search.css";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";

// This views shows the Search view to the user. Actually, you can only select one given destination.
class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: null,
      disabled: false
    };

    this.handleImgClick = this.handleImgClick.bind(this);
  }

  // on click at search field, set München Hbf, display it and disable the search form to prevent additonal clicks.
  handleOnClick() {
    this.setState({ search: "München Hbf", disabled: true });
  }

  // route the user to the booking view and sets the selected destination to localStorage if a destination is choosen. If not, display an alert.
  handleImgClick() {
    switch (this.state.search) {
      case null:
        swal({
          title: "Bitte ein Ziel wählen",
          icon: "info",
          timer: 3000
        });
        break;
      default:
        localStorage.setItem("destination", this.state.search);
        this.props.history.push({
          pathname: "/booking",
          state: { destination: this.state.search }
        });
    }
  }

  // renders the search view
  render() {
    return (
      <div className="mainSearch">
        <Header toSite="/assistant" actualSite="/search" visible={false} />
        <div className="floatingContentSearch">
          <div className="contentSearchInner">
            <p>Wo möchtest du hin?</p>
            <input
              className="inputSearch"
              type="search"
              readonly="true"
              value={this.state.search}
              onClick={this.handleOnClick.bind(this)}
              disabled={this.state.disabled}
            />
            <img
              className="proceedImg"
              src={require("../assets/Startpfeil.png")}
              alt="go to own profile"
              onClick={this.handleImgClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Search);
export { Search };
