import React from "react";
import { Journey } from "../components/Journey";
import "./Booking.css";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";

// this view shows the "Fahrt finden" view to the user and consists of the initialy given journeys which are displayed to the user
class Book extends React.Component {
  constructor(props) {
    super(props);

    const destination = localStorage.getItem("destination");
    this.handleBackButton = this.handleBackButton.bind(this);

    this.state = {
      destination: destination
    };
  }

  // go back to the search view
  handleBackButton() {
    this.props.history.push("/search");
  }

  // renders the view consisting of the header and the three different journeys. Here, a method could be implemented to get the journey data dinamicaly fetched from an API/backend
  render() {
    return (
      <div>
        <Header toSite="/search" actualSite="/booking" title="Fahrt finden" />

        <div className="body">
          <div className="destination">
            <img src={require("../assets/Location.png")} alt="destination" />
            <span className="destinationSpan">{this.state.destination}</span>
          </div>
          <div className="journeys">
            <Journey
              passengers="2"
              arrival="19:10"
              price="10"
              img_1={require("../assets/frank.png")}
              name_1="Frank"
              age_1="41"
              matching_1="100"
              img_2={require("../assets/theo.png")}
              name_2="Theo"
              age_2="17"
              matching_2="75"
              live={true}
              media="Fußball-EM 2024 | Frankreich - Kroatien ZDF-Live"
              destination={this.state.destination}
            />
            <Journey
              passengers="1"
              arrival="19:15"
              price="15"
              img_1={require("../assets/paula.png")}
              name_1="Paula"
              age_1="57"
              matching_1="25"
              live={false}
              media="Fußball"
              destination={this.state.destination}
            />
            <Journey
              passengers="0"
              arrival="19:00"
              price="30"
              img_1={require("../assets/neueFahrt.png")}
              name_1="Neue Fahrt"
              destination={this.state.destination}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Book);
export { Book };
