import React from "react";
import "./Header.css";
import { withRouter } from "react-router-dom";

// this component displays the header which is used in multiple views.
// there are different kinds of headers, some with a link to the Account view,
// some with a blue overlay and some with blue instead of white icons
class Header extends React.Component {
  static defaultProps = {
    profilelink: true,
    visible: true,
    blueIcons: false
  };

  // routes the user back to the previous view with props given of the previous view
  toSite() {
    this.props.history.push(this.props.toSite);
    console.log("GO to site: " + this.props.toSite);
  }

  // routes the user to the account view and sets the actual view to local storage for routing back at some time
  toAccount() {
    localStorage.setItem("backToSite", this.props.actualSite);

    this.props.history.push({
      pathname: "/account"
    });
  }

  // renders the header dependend on the given states. Make distinctions between them and then render it.
  render() {
    let profileImg;
    let isBlue = "";
    if (this.props.blueIcons) {
      isBlue = "Blue";
    }
    if (this.props.profilelink === true) {
      profileImg = (
        <img
          className="toProfile"
          src={require("../assets/Profil" + isBlue + ".png")}
          alt="go to own profile"
          onClick={this.toAccount.bind(this)}
        />
      );
    }
    let visible = null;
    if (this.props.visible === true) {
      visible = {
        background: "rgba(87, 98, 141, 1)",
        height: "4rem",
        textalign: "center"
      };
    } else {
      visible = {
        background: "rgba(87, 98, 141, 0)",
        borderStyle: "hidden",
        height: "4rem",
        position: "relative"
      };
    }

    return (
      <header className="headerApp" style={visible}>
        <div className="headerContent">
          <img
            className="backButton"
            src={require("../assets/Zurückpfeil.png")}
            alt="back button"
            onClick={this.toSite.bind(this)}
          />
          <span>{this.props.title}</span>
          {profileImg}
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
export { Header };
