import React from "react";
import { withRouter } from "react-router-dom";
import "./BookingConfirmation.css";

// this view shows the bookingConfirmation to the user after booking some journey
class BookingConfirmation extends React.Component {
  constructor(props) {
    super(props);

    // Get the variable live from localStorage to distinguish between a journey with live content or not
    const live = localStorage.getItem("live") === "true" ? true : false;
    console.log("LocalStore Live: " + live);
    const passengers = localStorage.getItem("passengers");
    console.log("LocalStore Passengers: " + passengers);

    this.state = {
      live: live,
      passengers: passengers,
      clicked: false
    };
    this.onButtonClick = this.onButtonClick.bind(this);
  }

  // on mounting the component start a timer, after five seconds redirect the user to the journeyInfos view
  componentDidMount() {
    setTimeout(this.onButtonClick, 5000);
  }

  // on click or after five seconds route to journeyInfos. To avoid multiple redirects cause of the timer, set state.clicked to true
  onButtonClick() {
    if (this.state.clicked === false) {
      this.setState({ clicked: true }, () =>
        this.props.history.push("/journey", {
          passengers: this.state.passengers
        })
      );
    }
  }

  // renders the view to the user. State.live distinguishes between live content or not
  render() {
    return (
      <div className="backgroundConfirmation" onClick={this.onButtonClick}>
        <div className="contentConfirmation">
          <img
            className="confirmationImg"
            src={require("../assets/Confirmation.png")}
            alt="booking confirmed"
          />
          <p className="confirmationP">
            Buchung <br /> erfolgreich!
          </p>
        </div>
        <div className="bottomOutterDiv">
          <div className="bottomInnerDiv">
            {this.state.live && (
              <p className="confirmationSuggestion">
                Schaue dir jetzt die Zusammenfassung von allem was du verpasst
                hast an.
              </p>
            )}
            {!this.state.live && (
              <p className="confirmationSuggestion">
                Mache deinen Mitfahrern jetzt einen Vorschlag, etwas gemeinsam
                zu schauen.
              </p>
            )}
            <img
              className="toSuggestionImg"
              src={require("../assets/Startpfeil.png")}
              alt="go to own profile"
              onClick={this.handleImgClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BookingConfirmation);
export { BookingConfirmation };
