import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Search from "./views/Search";
import * as serviceWorker from "./serviceWorker";
import Book from "./views/Booking";
import BookingConfirmation from "./views/BookingConfirmation";
import Infos from "./views/JourneyInfos";
import Profile from "./views/Profile";
import Account from "./views/Account";
import AccountSharing from "./views/AccountSharing";
import PageNotFound from "./views/PageNotFound";
import Assistant from "./views/Assistant";
import Welcome from "./views/Welcome";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

// function to handle window resize correctly
function FixBackgroundToScreen() {
  var bgImg = new Image();
  bgImg.src = document.body.background;

  if (bgImg.height / window.innerHeight < bgImg.width / window.innerWidth)
    document.body.style.backgroundSize = "auto 100%";
  else document.body.style.backgroundSize = "100% auto";
  console.log("WindowResized");
}

window.onresize = function() {
  FixBackgroundToScreen();
};

// the routes for the views.
const routing = (
  <Router basename={process.env.PUBLIC_URL}>
    <Switch>
      <Route exact path="/" component={Welcome} />
      <Route path="/assistant" component={Assistant} />} />
      <Route path="/search" component={Search} />} />
      <Route path="/booking" component={Book} />} />
      <Route path="/booked" component={BookingConfirmation} />} />
      <Route path="/journey" component={Infos} />
      <Route path="/profile" component={Profile} />
      <Route path="/account" component={Account} />
      <Route path="/accountSharing" component={AccountSharing} />
      <Route component={PageNotFound} />
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

export { Account };
