import React from "react";
import "./Passenger.css";
import { withRouter } from "react-router-dom";

//this component rendes a passenger component, which can be used when showing the passengers in the CurrentMedia screen.
class Passenger extends React.Component {

  //sets currend passengers with properties to the localStorage, when clicked
  handleProfileClick() {
    localStorage.setItem("profileName", this.props.name);
    localStorage.setItem("profileAge", this.props.age);
    localStorage.setItem("back", "/journey");
    this.props.history.push("/profile");
  }

  //renders a div in which the passenger is shown, with the given properties image, name, age and matching
  render() {
    return (
      <div
        className="passengerDiv"
        onClick={this.handleProfileClick.bind(this)}
      >
        <img className="passengerImg" src={this.props.img} alt="DummyPicture" />
        <div className="passengerInfos">
          <p className="passengerName">
            {this.props.name}
            <span className="passengerAge">({this.props.age})</span>
          </p>
          <p className="passengerMatching">
            {this.props.matching}% gemeinsame Interessen
          </p>
        </div>
      </div>
    );
  }
}
export default withRouter(Passenger);
export { Passenger };
