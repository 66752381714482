import React from "react";
import Header from "../components/Header";
import "./Account.css";
import { withRouter } from "react-router-dom";

// this view shows the "Mein Konto" view to the user.
class Account extends React.Component {
  constructor(props) {
    super(props);
    const backToSite = localStorage.getItem("backToSite");
    console.log("Go back to site in account: " + backToSite);

    this.state = {
      backToSite: backToSite
    };
  }

  // avoid losing present information, store own name and age in localStorage and afterwards route to the view "Profile"
  toProfile() {
    localStorage.setItem("profileName", "Stefan");
    localStorage.setItem("profileAge", "22");
    localStorage.setItem("back", "/account");
    this.props.history.push({
      pathname: "/profile"
    });
  }

  // route to the view "AccountSharing"
  toStreamingAccounts() {
    this.props.history.push("/accountSharing");
  }

  render() {
    return (
      <div className="mainAccountDiv">
        <div className="accountDiv">
          <Header
            title="Mein Konto"
            toSite={this.state.backToSite}
            profilelink={false}
          />
          <div className="upperLinks">
            <div className="link" onClick={this.toProfile.bind(this)}>
              Öffentliches Profil
              <img
                className="rightarrow"
                alt=">"
                src={require("../assets/Vorpfeil.png")}
              />
            </div>
            <div className="link" onClick={this.toStreamingAccounts.bind(this)}>
              Streaming Accounts
              <img
                className="rightarrow"
                alt=">"
                src={require("../assets/Vorpfeil.png")}
              />
            </div>
            <div className="dummyLink">
              Bezahldaten
              <img
                className="rightarrow"
                alt=">"
                src={require("../assets/Vorpfeil.png")}
              />
            </div>
            <div className="dummyLink">
              Einstellungen
              <img
                className="rightarrow"
                alt=">"
                src={require("../assets/Vorpfeil.png")}
              />
            </div>
          </div>
          <div className="lowerLinksDiv">
            <div className="lowerLinks">
              <div className="dummyLinkTop">Hilfe</div>
              <div className="dummyLink">AGB</div>
              <div className="dummyLink">Abmelden</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Account);
export { Account };
