import React from "react";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import "./BookingModalNew.css";

// shows the bookingModal popup if the user clicks on "Neue Fahrt" in booking view. This component is opend from journey component
class BookingModalNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  // close the modal if clicking outside it or on close button. Also fire a callback to the journey component to obtain a working open/close logic of the modal
  onCloseModal = () => {
    console.log("before closed inside children. State = " + this.state.open);
    this.setState(
      { open: false },
      () => this.props.triggerCloseModal(this.state.open),
      console.log("closed inside children. State = " + this.state.open)
    );
  };

  // safe all necessary variables to localStorage and route to bookingConfirmation view. This method books the given journey
  onButtonClick() {
    localStorage.setItem("price", this.props.price);
    localStorage.setItem("passengers", this.props.passengers);
    localStorage.setItem("live", this.props.live);

    this.props.history.push("/booked", {});
  }

  // renders the bookingModal popup to book the journey
  render() {
    const open = this.state;
    const bg = {
      transitionEnter: {
        transform: "scale(0)"
      },
      transitionEnterActive: {
        transform: "scale(1)",
        transition: "transform 1000ms ease"
      },
      transitionExit: {
        transform: "scale(1)"
      },
      transitionExitActive: {
        transition: "transform 1000ms ease"
      }
    };
    return (
      <div className="childDiv">
        <Modal
          open={open}
          onClose={this.onCloseModal}
          center
          styles={bg}
          animationDuration={1000}
        >
          <div>
            <div className="outterBooking">
              <div className="destinationBookingNew">
                <img
                  src={require("../assets/Location.png")}
                  alt="destination"
                />
                <span>{this.props.destination}</span>
              </div>
              <p className="arrivalBookingNew">
                Ankunft am Ziel: ~ {this.props.arrival} Uhr
              </p>
              <div className="watchingBookingDiv">
                <img
                  className="watchingBookingImg"
                  src={require("../assets/Fernseher.png")}
                  alt="actually watching"
                />
                <p className="watchingBookingSuggestion">
                  Mache deinen Mitfahreren einen Vorschlag, etwas gemeinsam zu
                  schauen.
                </p>
              </div>
              <div className="newJourneyBookingDiv">
                <img
                  className="newJourneyBookingImg"
                  src={require("../assets/neueFahrt.png")}
                  alt="create a new journey"
                />
                <p className="newJourneyBooking">neue Fahrt</p>
              </div>
              <div className="bookingDiv">
                <p className="bookingMaxPriceNew">max.</p>
                <p className="priceBookingNew">{this.props.price}€</p>
              </div>
              <div className="alignCenterDiv">
                <p className="payFormBookingNew">Zahlungsart: PayPal</p>
              </div>
              <div className="alignCenterDiv">
                <button
                  onClick={this.onButtonClick.bind(this)}
                  align="center"
                  className="buttonBooking"
                >
                  Buchen
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BookingModalNew);
