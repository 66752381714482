import React from "react";
import "./JourneyInfos.css"
import { withRouter } from "react-router-dom";
import { CarPosition } from "../components/CarPosition";
import  Media  from "../components/CurrentMedia";
import  Header  from "../components/Header";

// view shows infos of the booked journey
class Infos extends React.Component {
  constructor(props) {
    super(props);

    // Get the variable live from localStorage to distinguish between a journey with live content or not
    const live = localStorage.getItem("live") === "true" ? true : false;
    const passengers = localStorage.getItem("passengers");

    this.state = {
      live: live,
      passengers: passengers,
      slidedIn: false,
      mapSize: "small"
    };
  }

  // opens the Map or closes it when the button was pressed
  handleSliding(){
    this.setState({slidedIn: !this.state.slidedIn});
    if(this.state.slidedIn){
      this.setState({mapSize: "small"})
    }else{
      this.setState({mapSize: "big"})
    }
  }

  // renders the view,
  // State.live distinguishes between live content or not
  // State.slidedIn distinguishes between an open or closes map
  // State.mapSize manages css of the map
  render() {
    const slidedIn = this.state.slidedIn;
    let mediaInfos;
    let arrivalTime = "19:00";

    if(this.state.passengers === "1"){
      arrivalTime = "19:15";
    }else if (this.state.passengers === "2") {
      arrivalTime = "19:10";
    }

    if (slidedIn){ //show Map 
      mediaInfos = <div className="infos hidden">
      <button className="sliderButton" onClick={this.handleSliding.bind(this)}>
        <img
          className="upButton slider"
          src={require("../assets/Hochpfeil.png")}
          alt="up button"
        />
      </button>
        <Media passengers={this.state.passengers} contentSummary={this.state.live} disableScroll={true}/>
      </div>;
    }else{ //show Information about the Journey
      mediaInfos = <div className="infos shown">
      <button className="sliderButton" onClick={this.handleSliding.bind(this)}>
        <img
          className="downButton slider"
          src={require("../assets/Runterpfeil.png")}
          alt="down button"
        />
      </button>
      <Media passengers={this.state.passengers} contentSummary={this.state.live}/>
      </div>
      ;
    }
    return (
      <div>
        <Header toSite="/booking" actualSite="/journey" visible={false} blueIcons={true}/>
        <CarPosition size={this.state.mapSize} arrivalTime={arrivalTime}/>
        {mediaInfos}
      </div>
    );
  }
}

export default withRouter(Infos);

export { Infos };
