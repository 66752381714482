import React from "react";
import "./Assistant.css";
import { withRouter } from "react-router-dom";
import Swipeable from "react-swipeable";

// this view shows the Assistant to the user which consists of three different views
class Assistant extends React.Component {
  assistantObject = null;

  constructor(props) {
    super(props);

    // set initial state of the shown assistant
    this.state = {
      firstAssistant: true,
      secondAssistant: false,
      thirdAssistant: false,
      exitAssistant: false
    };

    this.handleSkipAssistant = this.handleSkipAssistant.bind(this);
    this.handleSecondAssistant = this.handleSecondAssistant.bind(this);
    this.handleThirdAssistant = this.handleThirdAssistant.bind(this);
    this.handleBackToFirstAssistant = this.handleBackToFirstAssistant.bind(
      this
    );
    this.handleBackToSecondAssistant = this.handleBackToSecondAssistant.bind(
      this
    );
    this.handleExitAssistant = this.handleExitAssistant.bind(this);
    this.swipingLeft = this.swipingLeft.bind(this);
    this.swipingRight = this.swipingRight.bind(this);
  }

  // click to skip the assistant and route to search view
  handleSkipAssistant() {
    this.props.history.push("/search");
  }

  // on click display second assistant
  handleSecondAssistant() {
    this.setState({ firstAssistant: false, secondAssistant: true });
  }

  // on click display third assistant
  handleThirdAssistant() {
    this.setState({ secondAssistant: false, thirdAssistant: true }, () =>
      console.log("Go To Third: " + this.state.thirdAssistant)
    );
  }

  // on click at third assistant exit and route to search view
  handleExitAssistant() {
    this.setState({ exitAssistant: true });
    this.props.history.push("/search");
  }

  // handle right swipe for second and third assistant to display the previous assistant
  swipingRight() {
    if (this.state.secondAssistant) {
      this.setState({
        firstAssistant: true,
        secondAssistant: false,
        thirdAssistant: false
      });
    }
    if (this.state.thirdAssistant) {
      this.setState({
        firstAssistant: false,
        secondAssistant: true,
        thirdAssistant: false
      });
    }
  }

  // handle left swipe for first and second assistant to display the next assistant or route to search view if swipped at third assistant
  swipingLeft() {
    if (this.state.firstAssistant) {
      this.setState({ firstAssistant: false, secondAssistant: true });
    }
    if (this.state.secondAssistant) {
      this.setState({
        firstAssistant: false,
        secondAssistant: false,
        thirdAssistant: true
      });
    }
    if (this.state.thirdAssistant) {
      this.handleExitAssistant();
    }
  }

  // if you cant swipe cause no touch device, you can toogle back to the first assistant when pressing the first small circle
  handleBackToFirstAssistant(ev) {
    this.setState(
      {
        secondAssistant: false,
        firstAssistant: true
      },
      () => this.setState({ thirdAssistant: false }),
      ev.stopPropagation()
    );
  }

  // if you cant swipe cause no touch device, you can toogle back to the second assistant when pressing the second small circle
  handleBackToSecondAssistant(ev) {
    this.setState(
      {
        secondAssistant: true
      },
      () => this.setState({ thirdAssistant: false, firstAssistant: false }),
      ev.stopPropagation()
    );
  }

  // render the view and distinguish between the three assistants
  render() {
    return (
      <div className="mainAssistant">
        <div className="floatingContent">
          <div className="contentAssistantInner">
            <p className="skipAssistant" onClick={this.handleSkipAssistant}>
              überspringen
            </p>
            {this.state.firstAssistant && (
              <Swipeable onSwipingLeft={this.swipingLeft}>
                <div
                  className="contentAssistantBorder"
                  onClick={this.handleSecondAssistant}
                >
                  <p className="topicAssistant">
                    Personalisierte Unterhaltung unterwegs
                  </p>
                  <img
                    className="heartImg"
                    src={require("../assets/Herz.png")}
                    alt="a heart symbol"
                  />
                  <p className="assistantText">
                    Netflix, Amazon Prime, Maxdome, die ZDF Mediathek - das
                    Streaming-Angebot ist vielfältig.
                  </p>
                  <p className="assistantText">
                    Für deine Unterhaltung im autonomen Taxi vereinen wir all
                    deine Accounts in einer App. Damit du unterwegs die volle
                    Auswahl hast und keine Zeit mit langem Suchen verlierst. So
                    kannst du deine Fahrt direkt genießen.
                  </p>
                  <div className="circleDiv">
                    <p className="filledCircle" />
                    <p className="circle" />
                    <p className="circle" />
                  </div>
                </div>
              </Swipeable>
            )}
            {this.state.secondAssistant && (
              <Swipeable
                onSwipingLeft={this.swipingLeft}
                onSwipingRight={this.swipingRight}
              >
                <div
                  className="contentAssistantBorder"
                  onClick={this.handleThirdAssistant}
                >
                  <p className="topicAssistant">
                    Mitfahrer mit gemeinsamen Interessen finden{" "}
                  </p>
                  <img
                    className="heartImg"
                    src={require("../assets/Personen.png")}
                    alt="a symbol showing 2 persons"
                  />
                  <p className="assistantText">
                    Wir ermitteln deine Interessen automatisch auf Basis deiner
                    verknüpften Profile von verschiedenen Streaming-Diensten. So
                    sind keine umständlichen Fragebögen notwendig!
                  </p>
                  <p className="assistantText">
                    Natürlich kannst du jederzeit selbst entscheiden, was andere
                    über dich wissen dürfen.
                  </p>
                  <div className="circleDiv">
                    <p
                      className="circle"
                      onClick={this.handleBackToFirstAssistant}
                    />
                    <p className="filledCircle" />
                    <p className="circle" />
                  </div>
                </div>
              </Swipeable>
            )}
            {this.state.thirdAssistant && (
              <Swipeable
                onSwipingLeft={this.swipingLeft}
                onSwipingRight={this.swipingRight}
              >
                <div
                  className="contentAssistantBorder"
                  onClick={this.handleExitAssistant}
                >
                  <p className="topicAssistant">
                    Videos, Filme, Serien & Live-Events gemeinsam erleben{" "}
                  </p>
                  <img
                    className="heartImg"
                    src={require("../assets/Screen.png")}
                    alt="a screen symbol"
                  />
                  <p className="assistantText">
                    Wenn du mit deinen Mitfahrern zusammen schaust, könnt ihr
                    dank 360° Erlebnis im autonomen Fahrzeug richtig in die
                    Geschichte eintauchen.
                  </p>
                  <p className="assistantText">
                    Falls du erst später dazu steigst, ist das gar kein Problem.
                    Unsere dynamischen Zusammenfassungen bringen dich auf den
                    neusten Stand.
                  </p>
                  <div className="circleDiv">
                    <p
                      className="circle"
                      onClick={this.handleBackToFirstAssistant}
                    />
                    <p
                      className="circle"
                      onClick={this.handleBackToSecondAssistant}
                    />
                    <p className="filledCircle" />
                  </div>
                </div>
              </Swipeable>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Assistant);
export { Assistant };
