import React from "react";
import PropTypes from "prop-types";

export const Code = ({ children }) => (
  <code
    style={{
      fontFamily: "monospace",
      fontSize: "15px",
      paddingLeft: "2px"
    }}
  >
    {children}
  </code>
);

Code.propTypes = {
  children: PropTypes.string.isRequired
};

export default { Code };
