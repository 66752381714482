import React from "react";
import { withRouter } from "react-router-dom";
import "./Welcome.css";

// shows the initial broadm logo to the user
class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
  }

  // redirect user after five seconds to assistant
  componentDidMount() {
    setTimeout(this.handleOnClick.bind(this), 5000);
  }

  // on click or after five seconds route to assistant. To avoid multiple redirects cause of the timer, set state.clicked to true
  handleOnClick() {
    if (this.state.clicked === false) {
      this.setState({ clicked: true }, () =>
        this.props.history.push("/assistant")
      );
    }
  }

  render() {
    return (
      <div className="welcomeDiv" onClick={this.handleOnClick.bind(this)} />
    );
  }
}

export default withRouter(Welcome);
