import React from "react";
import Switch from "react-switch";
import "./StreamingAccount.css";

// this component shows a single streamingAccount to the user in accountSharing view
class StreamingAccount extends React.Component {
  firstService = false;
  height = null;
  width = null;
  diameter = null;
  // like mediaQuery in css to adjust size to screen height/width
  x = window.matchMedia("(min-width: 700px) and (min-height: 700px)");

  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked,
      firstService: this.props.firstService
    };
    if (this.state.firstService === undefined) {
      this.setState({ firstService: false });
    }

    this.toggleSwitch = this.toggleSwitch.bind(this);
    this.screenTest = this.screenTest.bind(this);
    this.screenTest(this.x);
  }

  // check the screen size and adjust the height/width/diameter of the switch component
  screenTest(x) {
    if (x.matches) {
      this.height = 48;
      this.width = 96;
      this.diameter = 46;
    } else {
      this.height = 28;
      this.width = 56;
      this.diameter = 26;
    }
  }

  // toogle the switch between active and inactive and set its state to the localStorage to obtain a persistent behaviour
  toggleSwitch = () => {
    this.setState(
      prevState => {
        return {
          checked: !prevState.checked
        };
      },
      () => localStorage.setItem(this.props.serviceName, this.state.checked)
    );
  };

  // render the streamingAccount component using the external switch component. "isFirstClass" is for adjusting the first service correctly using css.
  render() {
    const isFirstClass = this.state.firstService ? "firstService" : "service";

    return (
      <div>
        <div className={isFirstClass}>
          <span className="serviceString">{this.props.serviceName}</span>
          <Switch
            className="toogleService"
            onChange={this.toggleSwitch}
            checked={this.state.checked}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#57628d"
            offColor="#e2e2e2"
            height={this.height}
            width={this.width}
            handleDiameter={this.diameter}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          />
        </div>
      </div>
    );
  }
}

export default StreamingAccount;
