import React from "react";
import "./MediaCover.css";

//this component is a clickable Media-cover, which is used in the CurrentMedia component.
//State.float is by default "left", able to set it on "right", which is used in the contentInfo-Screen
//State.click function, which is executed when clicked on the mediaCover
export class MediaCover extends React.Component {
  static defaultProps = {
    float: "left",
    click: null
  };

  // renders a MediaCover with properties name, image and broadcaster
  render() {
    let float = {float: this.props.float};
    return (
      <div className="MediaPicture" style={float} onClick={this.props.click}>
      <div className="MediaName">
      {this.props.broadcaster}
      </div>
        <img className="MediaPictureImg" alt={this.props.name} src={require("../assets/" + this.props.name + ".png")}></img>
      </div>
    );
  }
}
export default MediaCover;
