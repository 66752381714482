import React from "react";
import Header from "../components/Header";
import { withRouter } from "react-router-dom";
import StreamingAccount from "../components/StreamingAccount";
import "./AccountSharing.css";

// this view shows the "Streaming Accounts" view to the user. To persist the states, the state for each streaming account is taken from the localStorage
class AccountSharing extends React.Component {
  zdfState = localStorage.getItem("ZDF");
  netflixState = localStorage.getItem("Netflix");
  amazonState = localStorage.getItem("Amazon Prime");
  youtubeState = localStorage.getItem("Youtube");
  skyState = localStorage.getItem("Sky");
  maxdomeState = localStorage.getItem("Maxdome");
  googleState = localStorage.getItem("Google Play");
  itunesState = localStorage.getItem("iTunes");

  // because localStorage only store everything as string, we have to check the stringstate and transform it to a boolean for each streaming account
  constructor(props) {
    super(props);
    this.state = {};

    if (this.zdfState === "true" || this.zdfState === null) {
      this.state.zdfState = true;
    } else if (this.zdfState === "false") {
      this.state.zdfState = false;
    }

    if (this.netflixState === "true" || this.netflixState === null) {
      this.state.netflixState = true;
    } else if (this.netflixState === "false") {
      this.state.netflixState = false;
    }

    if (this.amazonState === "true" || this.amazonState === null) {
      this.state.amazonState = true;
    } else if (this.amazonState === "false") {
      this.state.amazonState = false;
    }

    if (this.youtubeState === "true" || this.youtubeState === null) {
      this.state.youtubeState = true;
    } else if (this.youtubeState === "false") {
      this.state.youtubeState = false;
    }

    if (this.skyState === "true") {
      this.state.skyState = true;
    } else if (this.skyState === "false" || this.netflixState === null) {
      this.state.skyState = false;
    }

    if (this.maxdomeState === "true") {
      this.state.maxdomeState = true;
    } else if (this.maxdomeState === "false" || this.netflixState === null) {
      this.state.maxdomeState = false;
    }

    if (this.googleState === "true") {
      this.state.googleState = true;
    } else if (this.googleState === "false" || this.netflixState === null) {
      this.state.googleState = false;
    }

    if (this.itunesState === "true") {
      this.state.itunesState = true;
    } else if (this.itunesState === "false" || this.netflixState === null) {
      this.state.itunesState = false;
    }
  }

  // build the view with streamingAccount components
  render() {
    return (
      <div>
        <Header
          toSite="/account"
          title="Streaming Accounts"
          profilelink={false}
        />
        <div className="services">
          <StreamingAccount
            firstService={true}
            checked={this.state.zdfState}
            /* toogleState={this.toogleState.bind(this)} */
            serviceName="ZDF"
          />
          <StreamingAccount
            checked={this.state.netflixState}
            serviceName="Netflix"
          />
          <StreamingAccount
            checked={this.state.amazonState}
            serviceName="Amazon Prime"
          />
          <StreamingAccount
            checked={this.state.youtubeState}
            serviceName="Youtube"
          />
          <StreamingAccount checked={this.state.skyState} serviceName="Sky" />
          <StreamingAccount
            checked={this.state.maxdomeState}
            serviceName="Maxdome"
          />
          <StreamingAccount
            checked={this.state.googleState}
            serviceName="Google Play"
          />
          <StreamingAccount
            checked={this.state.itunesState}
            serviceName="iTunes"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(AccountSharing);
export { AccountSharing };
